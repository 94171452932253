import React from 'react'
import styled from 'styled-components'

export const InnerContent = ({ destinationData }) => {
    return (
        <Section>
            <Wrapper className="wrapper">
                <Title>Why Study in {destinationData?.country_name}?</Title>
                <FirstContainer>
                    <ImageContainer backgroundImage={destinationData?.background_image}/>
                    <Description dangerouslySetInnerHTML={{ __html: destinationData?.description }} />
                </FirstContainer>
                <SecondContainer>
                <Description dangerouslySetInnerHTML={{ __html: destinationData?.description2 }} />
                </SecondContainer>
            </Wrapper>
        </Section>
    )
}

const Section = styled.div`
    margin-top: 120px;
    text-align: justify;
    @media (max-width:1023px){
        margin-top: 80px;
    }
`

const Wrapper = styled.div`
    margin-bottom: 50px;
`;

const Title = styled.h1`
    font-size: 40px;
    text-align: center;
    color: #1f93b0;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-bold";
    padding: 50px 0;
    @media (max-width:1023px){
        padding: 30px 0;
        font-size: 32px;
    }
`

const FirstContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 40px;
    @media (max-width:1023px){
        grid-template-columns: repeat(1,1fr);
        gap: 16px;
    }
`
const ImageContainer = styled.div`
    background-image: url(${props => props.backgroundImage});
    background-size: cover; 
    background-position: center; 
    width: 100%;
    height: 100%;
    border-radius: 20px;
    @media (max-width:1023px){
        height: 350px;
    }
`;


const Description = styled.div`
    line-height: 156% !important;
  p span {
    color: black !important;
    
  }
  h1, h2, h3, h4, h5, h6 {
    color: #1f93b0 !important;
  }
  strong {
    color: #1f93b0 !important;
  }
`;

const SecondContainer = styled.div`
    margin-top: 20px;
`