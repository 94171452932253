import React, { useState } from "react";
import styled from "styled-components";

import close from "../../../../assets/images/header/close_btn.svg";
import flag from "../../../../assets/images/header/flag.svg";
import { useNavigate } from "react-router-dom";

function MobileNav({ destinations, modal, setmodal }) {
  const [active, setActive] = useState(null);
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setmodal(false);
  };

  const handleActive = (value) => {
    if (active === value) {
      setActive(null);
    } else {
      setActive(value);
    }
  };

  const handleNavigate = (url) => {
    // handleCloseModal();
    navigate(url);
  };

  return (
    <Section className={modal && "active"}>
      <Menu>
        <Item className="top">
          <Title>Menu</Title>
          <Icon className="close" onClick={handleCloseModal}>
            <img src={close} alt="close-img" />
          </Icon>
        </Item>
        <MenuConatiner>
          <Item className="dropdown">
            <ItemContainer
              className={
                active === "studyNav" || active === "Destinations"
                  ? "active"
                  : null
              }
              onClick={() => handleActive("studyNav")}
            >
              Study
              <Icon>
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.326679 6.01716C0.32548 6.45404 0.678668 6.80723 1.11555 6.80603L10.2803 6.78089L7.17555 9.88568C6.86578 10.1954 6.8644 10.6963 7.17248 11.0044C7.48055 11.3124 7.98141 11.3111 8.29117 11.0013L12.7501 6.54242C13.0598 6.23266 13.0612 5.7318 12.7531 5.42373L8.31869 0.989297C8.01062 0.681226 7.50976 0.6826 7.19999 0.992367C6.89023 1.30213 6.88885 1.80299 7.19692 2.11106L10.2847 5.19881L1.11989 5.22396C0.683009 5.22516 0.327878 5.58029 0.326679 6.01716Z"
                    fill="#696969"
                  />
                </svg>
              </Icon>
            </ItemContainer>
            {active === "studyNav" || active === "Destinations" ? (
              <ItemInner>
                <Item
                  onClick={() => handleNavigate("/find-a-course")}
                  className="inner"
                >
                  Find a Course
                </Item>
                <Item
                  className="inner"
                  onClick={() => handleActive("Destinations")}
                >
                  Destinations
                  <svg
                    width="13"
                    height="12"
                    viewBox="0 0 13 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.326679 6.01716C0.32548 6.45404 0.678668 6.80723 1.11555 6.80603L10.2803 6.78089L7.17555 9.88568C6.86578 10.1954 6.8644 10.6963 7.17248 11.0044C7.48055 11.3124 7.98141 11.3111 8.29117 11.0013L12.7501 6.54242C13.0598 6.23266 13.0612 5.7318 12.7531 5.42373L8.31869 0.989297C8.01062 0.681226 7.50976 0.6826 7.19999 0.992367C6.89023 1.30213 6.88885 1.80299 7.19692 2.11106L10.2847 5.19881L1.11989 5.22396C0.683009 5.22516 0.327878 5.58029 0.326679 6.01716Z"
                      fill="#696969"
                    />
                  </svg>
                </Item>
                <Item
                  className="inner"
                  onClick={() => handleNavigate("/study-abroad")}
                >
                  Study abroad
                </Item>
                <Item
                  className="inner"
                  onClick={() => handleNavigate("/ausbildung-FSJ-&-aupair")}
                >
                  Ausbildung, FSJ & Aupair
                </Item>
              </ItemInner>
            ) : null}
          </Item>
          <Item onClick={() => handleNavigate("/jobs")}>Work</Item>
          <Item className="dropdown">
            <ItemContainer
              className={
                active === "migrate" || active === "Destinations"
                  ? "active"
                  : null
              }
              onClick={() => handleActive("migrate")}
            >
              Migrate
              <Icon>
                <svg
                  width="13"
                  height="12"
                  viewBox="0 0 13 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.326679 6.01716C0.32548 6.45404 0.678668 6.80723 1.11555 6.80603L10.2803 6.78089L7.17555 9.88568C6.86578 10.1954 6.8644 10.6963 7.17248 11.0044C7.48055 11.3124 7.98141 11.3111 8.29117 11.0013L12.7501 6.54242C13.0598 6.23266 13.0612 5.7318 12.7531 5.42373L8.31869 0.989297C8.01062 0.681226 7.50976 0.6826 7.19999 0.992367C6.89023 1.30213 6.88885 1.80299 7.19692 2.11106L10.2847 5.19881L1.11989 5.22396C0.683009 5.22516 0.327878 5.58029 0.326679 6.01716Z"
                    fill="#696969"
                  />
                </svg>
              </Icon>
            </ItemContainer>
            {active === "migrate" || active === "Destinations" ? (
              <ItemInner>
                <Item
                  onClick={() => handleNavigate("/why-to-canada")}
                  className="inner"
                >
                  Migrate to Canada
                </Item>
                <Item
                  className="inner"
                  onClick={() => handleNavigate("/why-to-australia")}
                >
                  Migrate to Australia
                </Item>
              </ItemInner>
            ) : null}
          </Item>
          <Item onClick={() => handleNavigate("/blogs")}>Blog</Item>
          <Item onClick={() => handleNavigate("/loan-assistance")}>
            Loan Assistance
          </Item>
          <Item onClick={() => handleNavigate("/webinars")}>Webinars</Item>
          <Item onClick={() => handleNavigate("/testimonials")}>
            Testimonials
          </Item>
          <Item onClick={() => handleNavigate("/careers")}>Careers</Item>
          <Item onClick={() => handleNavigate("/about")}>About Us</Item>
          <Item onClick={() => handleNavigate("/news-and-events")}>News And Events</Item>
          <Item onClick={() => handleNavigate("/contact")}>Contact Us</Item>
          <Item
            className="btn"
            onClick={() => handleNavigate("/book-appointment")}
          >
            <button>
              <i class="ri-arrow-right-s-fill"></i>
              <span>Book an Appointment</span>
            </button>
          </Item>
        </MenuConatiner>
      </Menu>
      <Destination className={active === "Destinations" && "active"}>
        <Item className="top">
          <Title>Choose Your Destination</Title>
          <Icon className="close" onClick={() => handleActive("studyNav")}>
            <img src={close} alt="close-img" />
          </Icon>
        </Item>
        <Box>
          {destinations?.map((item) => (
            <BoxItem onClick={() => navigate(`/destination/${item?.id}`)}>
              <BoxIcon className="country_img">
                <img src={item?.country_image} alt="" />
              </BoxIcon>
              <span>{item?.country_name}</span>
              <BoxIcon className="svg">
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.843931 9.48691C1.152 9.79668 1.65149 9.79668 1.95956 9.48691L8.42226 2.98865L8.42226 7.37949C8.42226 7.81756 8.77545 8.1727 9.21113 8.17269C9.64681 8.1727 10 7.81756 10 7.37949L10 1.07367C10 0.635596 9.64681 0.280463 9.21113 0.280463L2.9399 0.280468C2.50422 0.280468 2.15103 0.6356 2.15103 1.07368C2.15103 1.51175 2.50422 1.86689 2.9399 1.86689L7.30664 1.86688L0.843931 8.36515C0.535859 8.67491 0.535859 9.17715 0.843931 9.48691Z"
                    fill="#696969"
                  />
                </svg>
              </BoxIcon>
            </BoxItem>
          ))}
        </Box>
        <Bottom>
          Didn’t find your dream destination? Proceed without choosing a country
          and <a href="/find-a-course">explore courses now</a>
        </Bottom>
      </Destination>
    </Section>
  );
}

export default MobileNav;

const Section = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(248, 248, 248, 1);
  padding: 20px 0;
  transform: translateX(100%);
  transition: transform 0.4s ease-in-out;

  &.active {
    transform: translateX(0%);
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 95vh;
`;

const Item = styled.div`
  padding: 0px 25px;
  min-height: 50px;
  display: flex;
  align-items: center;
  color: #000;
  gap: 10px;

  &.top {
    justify-content: space-between;
  }

  &.btn {
    background-color: rgb(21 155 169) !important;
    color: #fff;
    display: none;

    button {
      border: none;
      width: 100%;
      margin: 0 auto;
      text-align: left;
      font-size: 16px;
      padding: 0;
      height: 40px;
      background-color: transparent;
      color: #fff;
    }

    @media screen and (max-width: 768px) {
      display: block;
    }
  }

  &.dropdown {
    flex-direction: column;
    align-items: unset;
    padding: 0;
    gap: 0;
  }

  &:hover:not(.top, .dropdown) {
    background-color: rgba(0, 174, 192, 1);
    color: #fff;
    svg {
      transform: rotate(45deg);
      path {
        fill: #ffffff;
      }
    }
  }

  &.inner {
    padding: 0px 40px;
  }

  @media screen and (max-width: 580px) {
    padding: 0px 15px;

    &.inner {
      padding: 0px 30px;
    }
  }
`;

const Destination = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  position: relative;
  position: absolute;
  background-color: #fff;
  gap: 20px;
  inset: 0;
  padding: 10px 0;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  &.active {
    transform: translateX(0%);
  }
`;

const Title = styled.div`
  border-bottom: 2px solid rgba(86, 87, 87, 1);
  width: 90%;
  padding: 5px 0;
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &.close {
    cursor: pointer;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  gap: 10px;
  min-height: 50px;
  align-items: center;
  padding: 0px 25px;

  &:hover {
    background-color: rgba(0, 174, 192, 1);
    color: #fff;

    svg {
      transform: rotate(45deg);
      path {
        fill: #ffffff;
      }
    }
  }

  &.active {
    background-color: rgba(0, 174, 192, 1);
    color: #fff;

    svg {
      transform: rotate(45deg);
      path {
        fill: #ffffff;
      }
    }
  }

  @media screen and (max-width: 580px) {
    padding: 0px 15px;
  }
`;

const ItemInner = styled.div`
  /* min-height: 500px; */
`;

const Box = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 30px;
  margin-top: 10px;
  margin: 0 25px;

  @media screen and (max-width: 580px) {
    gap: 10px;
  }
`;

const BoxItem = styled.div`
  display: flex;
  align-items: center;
  width: 43%;
  min-width: 150px;
  max-height: 45px;
  cursor: pointer;
  color: rgba(86, 87, 87, 1);
  border-bottom: 1px solid rgba(86, 87, 87, 1);
  padding-bottom: 5px;

  span {
    width: 50%;
    text-align: start;
    font-size: 16px;
    text-transform: uppercase;
  }

  &:hover {
    color: rgba(0, 174, 192, 1);

    svg {
      transform: rotate(45deg);
      path {
        fill: rgba(0, 174, 192, 1);
      }
    }
  }
  @media screen and (min-width: 581px) {
    span{
      padding-left: 10px;
      white-space: nowrap;
    }
  }
  @media screen and (max-width: 580px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const BoxIcon = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;

  &.svg {
    justify-content: flex-end;
  }

  img {
    max-width: 50px;
    width: 100%;
    object-fit: contain;
  }

  &.country_img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;

    img {
      object-fit: cover;
      max-width: 65px;
    }
  }
`;

const Bottom = styled.div`
  text-align: center;
  font-size: 15px;
  color: rgba(86, 87, 87, 1);
  margin: 0 20px;

  a {
    color: rgba(0, 174, 192, 1);
  }
`;

const MenuConatiner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
