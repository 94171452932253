import React, { useEffect, useState } from "react";
import HeaderEdit from "../../includes/HeaderEdit";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  TwitterShareButton,
  LinkedinShareButton,
  FacebookShareButton,
} from "react-share";

import Footer from "../../includes/Footer";
import rightArrow from "../../../../../assets/images/jobs/icons/right-arrow.svg";
import joblocation from "../../../../../assets/images/jobs/icons/job-location.svg";
import program from "../../../../../assets/images/courselisting/icons/program.svg";
import calender from "../../../../../assets/images/courselisting/icons/calendar.svg";
import duration from "../../../../../assets/images/courselisting/icons/duration.svg";
import intake from "../../../../../assets/images/courselisting/icons/intake.svg";
import study_area from "../../../../../assets/images/courselisting/icons/study_area.svg";
import abc from "../../../../../assets/images/courselisting/icons/abc.svg";
import disipline_area from "../../../../../assets/images/courselisting/icons/discipilne_area.svg";
import dot from "../../../../../assets/images/courselisting/icons/dot.svg";
import uni from "../../../../../assets/images/courselisting/icons/uni.svg";
import university from "../../../../../assets/images/courselisting/university.png";

import fb from "../../../../../assets/images/jobs/icons/fb.svg";
import links from "../../../../../assets/images/jobs/icons/links.svg";
import x from "../../../../../assets/images/jobs/icons/x.svg";
import linkedin from "../../../../../assets/images/jobs/icons/linkedin.svg";
import { accountsConfig } from "../../../../../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import CourseApplyModal from "../../../../modals/CourseApplyModal";

function CourseDetail() {
  const [modal, setmodal] = useState(false);
  const [courseInfo, setCourseInfo] = useState({});
  const [copied, setcopied] = useState(false);
  const [applyModal, setapplyModal] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [relatedBlogs, setRelatedBlogs] = useState([])

  const url = window.location.href;
  const points = [
    "Great troubleshooting and analytical skills combined with the desire to tackle challenges head-on",
    "3+ years of experience in back-end development working either with multiple smaller projects simultaneously or large-scale applications",
    "Experience with HTML, JavaScript, CSS, PHP, Symphony and/or Laravel Working regularly with APIs and Web Services (REST, GrapthQL, SOAP, etc)",
    "Have experience/awareness in Agile application development, commercial off-the-shelf software, middleware, servers and storage, and database management",
    "Familiarity with version control and project management systems (e.g., Github, Jira)",
    "Great troubleshooting and analytical skills combined with the desire to tackle challenges head-on",
    "Ambitious and hungry to grow your career in a fast-growing agency",
  ];
  const navigate = useNavigate();

  const { id } = useParams();

  useEffect(() => {
    accountsConfig.get(`study/single-sub-courses/${id}`).then((response) => {
      const { data, StatusCode, related_blogs } = response.data.app_data;
      if (StatusCode === 6000) {
        setCourseInfo(data);
        setRelatedBlogs(related_blogs)
      } else {
        setCourseInfo({});
      }
    });
  }, [id]);

  const handleCopy = () => {
    setcopied(true);
    setTimeout(() => {
      setcopied(false);
    }, 3000);
  };

  const currentYear = new Date().getFullYear();
  const nextYear = currentYear+1
  console.log(nextYear);


  return (
    <>
      <Section>
        <HeaderEdit backgroundCl={"#00AEC0"} />
        <Container className="wrapper">
          <Title>Course Details</Title>
          <CouseInfoBox>
            <Info>
              <UniversityLogo>
                <img src={courseInfo?.image || university} alt="" />
              </UniversityLogo>
              <More>
                <Department>{courseInfo?.name}</Department>
                <University>{courseInfo?.university_name}</University>
                <Country>
                  <span>Country :</span> {courseInfo?.country}
                </Country>
              </More>
            </Info>
            <ApplyNow onClick={() => setapplyModal(true)}>
              Apply Now <img src={rightArrow} alt="" />
            </ApplyNow>
          </CouseInfoBox>
          <DetailSection>
            <Left>
              <Box>
                <Heading>Requirements:</Heading>
                <Points>
                  {courseInfo?.requirements?.map((item) => (
                    <Point>
                      <Icon className="dot">
                        <img src={dot} alt="" />
                      </Icon>
                      {item}
                    </Point>
                  ))}
                </Points>
              </Box>
              <Box>
                <Heading>Course Description</Heading>
                <Desc
                  dangerouslySetInnerHTML={{
                    __html: courseInfo?.description,
                  }}
                />
              </Box>
              <Box>
                <Heading>Career Opportunities:</Heading>
                <Desc
                  dangerouslySetInnerHTML={{
                    __html: courseInfo?.career_oppurtunity,
                  }}
                />
              </Box>
            </Left>
            <Right>
              {/* <Top>
                <InfoBox className="first">
                  <AnInfo>Salary (USD)</AnInfo>
                  <Salary>{"jobInfo?.salary"}</Salary>
                  <Span>Yearly salary</Span>
                </InfoBox>
                <InfoBox>
                  <Icon>
                    <img src={joblocation} alt="" />
                  </Icon>
                  <AnInfo>Job Location</AnInfo>
                  <Span>{"jobInfo?.location"}</Span>
                </InfoBox>
              </Top> */}
              <Bottom>
                <Heading>Course Overview</Heading>
                <OverviewContainer>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={program} alt="" />
                    </Icon>
                    <Div>
                      <Span>Program</Span>
                      <H6>{courseInfo?.programme}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={calender} alt="" />
                    </Icon>
                    <Div>
                      <Span>Year</Span>
                      <H6>{courseInfo?.year}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={duration} alt="" />
                    </Icon>
                    <Div>
                      <Span>Duration</Span>
                      <H6>{courseInfo?.duration_display} Year</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={intake} alt="" />
                    </Icon>
                    <Div>
                      <Span>In Take</Span>
                      <H6>{courseInfo?.intake_display}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={study_area} alt="" />
                    </Icon>
                    <Div>
                      <Span>Study area</Span>
                      <H6>Accounts</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={abc} alt="" />
                    </Icon>
                    <Div>
                      <Span>ESL/ELP</Span>
                      <H6>{courseInfo?.esl}</H6>
                    </Div>
                  </IconBox>
                  <IconBox>
                    <Icon className="job-icons">
                      <img src={disipline_area} alt="" />
                    </Icon>
                    <Div>
                      <Span>Discipline Area</Span>
                      <H6>None</H6>
                    </Div>
                  </IconBox>
                </OverviewContainer>
                <Line />
                <LinksContainer>
                  <Heading>Share this job:</Heading>
                  <LinkBox>
                    <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                      <Copy>
                        <Icon className="link">
                          <img src={links} alt="" />
                        </Icon>
                        {copied ? "Copied" : "Copy Links"}
                      </Copy>
                    </CopyToClipboard>
                    <SMIcon>
                      <LinkedinShareButton url={url}>
                        <Icon className="sm">
                          <img src={linkedin} alt="" />
                        </Icon>
                      </LinkedinShareButton>
                    </SMIcon>
                    <SMIcon>
                      <FacebookShareButton url={url}>
                        <Icon className="sm">
                          <img src={fb} alt="" />
                        </Icon>
                      </FacebookShareButton>
                    </SMIcon>
                    <SMIcon>
                      <TwitterShareButton url={url}>
                        <Icon className="sm">
                          <img src={x} alt="" />
                        </Icon>
                      </TwitterShareButton>
                    </SMIcon>
                  </LinkBox>
                </LinksContainer>
              </Bottom>
            </Right>
          </DetailSection>
          {/* job share for mobile and tabs */}
          <LinksContainer className="sm-version">
            <Heading>Share this job:</Heading>
            <LinkBox>
              <CopyToClipboard text={url} onCopy={() => handleCopy()}>
                <Copy>
                  <Icon className="link">
                    <img src={links} alt="" />
                  </Icon>
                  {copied ? "Copied" : "Copy Links"}
                </Copy>
              </CopyToClipboard>
              <SMIcon>
                <LinkedinShareButton url={url}>
                  <Icon className="sm">
                    <img src={linkedin} alt="" />
                  </Icon>
                </LinkedinShareButton>
              </SMIcon>
              <SMIcon>
                <FacebookShareButton url={url}>
                  <Icon className="sm">
                    <img src={fb} alt="" />
                  </Icon>
                </FacebookShareButton>
              </SMIcon>
              <SMIcon>
                <TwitterShareButton url={url}>
                  <Icon className="sm">
                    <img src={x} alt="" />
                  </Icon>
                </TwitterShareButton>
              </SMIcon>
            </LinkBox>
          </LinksContainer>
          <ApplyNowSticky onClick={() => setapplyModal(true)}>
            Apply Now
          </ApplyNowSticky>
        </Container>
        <Separator />
        {courseInfo?.related_course?.length > 0 ? (
          <Container className="wrapper second">
            <Title2>Related Courses</Title2>
            <CourseList>
              {courseInfo?.related_course?.map((x) => (
                <Card>
                  <UniversityInfo>
                    <LogoImg>
                      <img src={x?.image || university} alt="" />
                    </LogoImg>
                    <InfoDiv>
                      <Department1>{x.course_name}</Department1>
                      <NameInfo>{x.university_name}</NameInfo>
                      <CountryInfo>
                        <span>Country : </span>
                        {x.country}
                      </CountryInfo>
                    </InfoDiv>
                  </UniversityInfo>

                  <Features>
                    <List>
                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>Year: </span>
                          {x?.year}
                          {/* {nextYear} */}
                        </FeatureName>
                      </SingleItem>
                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>In Take: </span>
                          {x?.intake}
                        </FeatureName>
                      </SingleItem>
                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>Study Area: </span>
                          {x?.course_name}
                        </FeatureName>
                      </SingleItem>
                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>Duration: </span>
                          {x?.duration}
                        </FeatureName>
                      </SingleItem>
                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>Discipline Area: </span>
                          {x?.name}
                        </FeatureName>
                      </SingleItem>
                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>ESL/ELP Available: </span>
                          {x?.esl}
                        </FeatureName>
                      </SingleItem>

                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>Program: </span>
                          {x?.programme}
                        </FeatureName>
                      </SingleItem>

                      <SingleItem>
                        <Dot />
                        <FeatureName>
                          <span>Requirements: </span>
                          {x?.requirements
                            ? x?.requirements.map((it, indx) => {
                              if (x?.requirements?.length > 2) {
                                if (indx <= 2) {
                                  if (indx === 2) {
                                    return `...`;
                                  } else {
                                    return `${it},`;
                                  }
                                }
                              } else {
                                if (indx !== x?.requirements?.length - 1) {
                                  return `${it},`;
                                } else {
                                  return `${it}`;
                                }
                              }
                            })
                            : "None"}
                        </FeatureName>
                      </SingleItem>
                    </List>
                  </Features>

                  <EnquireBtns>
                    {/* <EnquireBtn>Enquire Now</EnquireBtn> */}
                    <ViewMoreDetails
                      onClick={() => navigate(`/course/${x?.id}`)}
                    >
                      View Course
                      <span>
                        <img src={rightArrow} alt="" />
                      </span>
                    </ViewMoreDetails>
                  </EnquireBtns>
                </Card>
              ))}
            </CourseList>
          </Container>
        ) : null}
        {relatedBlogs.length > 0 &&
          <Container className="wrapper second">
            <Title2>Related Blogs</Title2>
            <BLogCardContainer>
              {relatedBlogs.map((item, index) => (
                <BlogCard key={index} item={item} />
              ))}
            </BLogCardContainer>
          </Container>
         }
      <Footer />

      <CourseApplyModal
        course={courseInfo}
        modal={applyModal}
        setModal={setapplyModal}
        setLoginModal={setLoginModal}
      />
    </Section >
    </>
  );
}

const BlogCard = ({ item }) => {
  const navigate = useNavigate()
  return (
    <BlogCardWrapper onClick={() => navigate(`/blogs/${item?.id}`)}>
      <ImageContainer backgroundImage={item?.image || ""} />
      <BlogTitle>
        {item?.title}
      </BlogTitle>
    </BlogCardWrapper>
  )
}

export default CourseDetail;

const Section = styled.section`
  background-color: #f8f8f8;
  padding-top: 160px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px;

  &.second {
    padding: 80px 0;
    gap: 65px;
  }
`;

const Title = styled.h1`
  color: #1897b2;
  text-align: center;
  font-size: 45px;
  margin-bottom: 30px;

  @media screen and (max-width: 580px) {
    font-size: 32px;
    margin-bottom: 0;
  }
`;

const CouseInfoBox = styled.div`
  padding: 30px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 580px) {
    padding: 15px;
  }
`;

const Info = styled.div`
  display: flex;
  gap: 12px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const JobName = styled.h1`
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @media screen and (max-width: 480px) {
    font-size: 28px;
  }

  @media screen and (max-width: 350px) {
    font-size: 24px;
  }
`;

const ApplyNow = styled.div`
  /* height: 100%; */
  background-color: #00aec0;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  padding: 8px 50px;
  align-items: center;
  gap: 7px;
  align-self: stretch;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: none;
  }

  img {
    width: 15px;
  }
`;

const OtherDetail = styled.div`
  display: flex;
  gap: 10px;

  @media screen and (max-width: 768px) {
    justify-content: space-between;
  }
  @media screen and (max-width: 380px) {
    flex-direction: column;
  }
`;

const DetailSection = styled.div`
  display: flex;
  gap: 65px;

  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    gap: 30px;
  }
`;

const Company = styled.p`
  color: #474c54;
  font-size: 16px;
`;

const Type = styled.div`
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  text-transform: capitalize;
  background-color: #e7f0fa;
  color: #00aec0;

  @media screen and (max-width: 380px) {
    max-width: 70px;
  }
`;

const Left = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Right = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (max-width: 1080px) {
    width: 100%;
  }
`;

const Heading = styled.h3`
  font-size: 17px;
  font-weight: unset;
  margin: 0;
  padding: 0;
  font-family: "ubuntu-medium";
`;

const Desc = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: unset;
  line-height: 22px;
  color: #2b2b2b;
  text-align: justify;
  text-transform: capitalize;
`;

const Points = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0;
`;

const Point = styled.div`
  font-size: 16px;
  font-style: normal;
  font-weight: unset;
  line-height: 22px;
  color: #2b2b2b;
  text-align: justify;
  display: flex;
  gap: 5px;
  align-items: center;
`;

const Top = styled.div`
  box-sizing: border-box;
  padding: 30px 15px;
  background-color: #fff;
  border: 2px solid #e7f0fa;
  display: flex;

  @media screen and (max-width: 425px) {
    padding: 15px;
  }

  @media screen and (max-width: 380px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const Bottom = styled.div`
  display: flex;
  gap: 30px;
  box-sizing: border-box;
  padding: 30px;
  background-color: #fff;
  border: 2px solid #e7f0fa;
  flex-direction: column;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  width: 50%;

  &.first {
    border-right: 2px solid #e7f0fa;
  }

  @media screen and (max-width: 380px) {
    width: 80%;
    margin: 0 auto;
    padding: 8px 0;

    &.first {
      border-right: none;
      border-bottom: 2px solid #e7f0fa;
    }
  }
`;

const AnInfo = styled.h3`
  font-size: 17px;
  font-weight: unset;
  margin: 0;
  padding: 0;
  font-family: "ubuntu-medium";
`;

const Salary = styled.div`
  color: #00aec0;
`;

const Span = styled.p`
  font-family: "ubuntu-regular";
  margin: 0;
  padding: 0;
  color: #767f8c;
  font-size: 13px;
  font-weight: unset;
  font-style: normal;
  line-height: 22px;
  text-align: center;
  width: 100%;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;

  &.dot {
    max-width: 8px;
  }

  &.job-icons {
    justify-content: flex-start;
  }

  &.link {
    width: 24px;
    height: 24px;
  }

  &.sm {
    width: 20px;
    height: 20px;
    img {
      width: 20px;
      height: 20px;
    }
  }

  img {
    width: 30px;
    object-fit: contain;
  }
`;

const OverviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const IconBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 46%;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(231, 240, 250, 1);
  padding: 30px;
  box-sizing: border-box;

  &:first-child {
    border-left: none;
    border-top: none;
  }
  &:nth-child(2) {
    border-top: none;
    border-right: none;
  }

  &:nth-child(odd) {
    border-left: none;
  }

  &:nth-child(even) {
    border-right: none;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const H6 = styled.h6`
  font-size: 14px;
  font-weight: unset;
  margin: 0;
  padding: 0;
  color: black;
  text-align: center;
  width: 100%;
  text-transform: capitalize;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  background-color: #e7f0fa;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  &.sm-version {
    display: none;
  }

  @media screen and (max-width: 1080px) {
    display: none;

    &.sm-version {
      display: flex;
    }
  }
`;

const LinkBox = styled.div`
  display: flex;
  gap: 10px;
`;

const Copy = styled.div`
  background-color: #e7f0fa;
  padding: 8px;
  display: flex;
  gap: 8px;
  color: #00aec0;
  font-size: 16px;
  font-style: normal;
  font-weight: unset;
  line-height: 22px;
  cursor: pointer;
  min-width: 130px;
`;

const SMIcon = styled.div`
  padding: 10px;
  background-color: #e7f0fa;
  cursor: pointer;
`;

const Separator = styled.div`
  width: 100%;
  background-color: #edeff5;
  height: 1.5px;
`;

const Title2 = styled.h1`
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const CourseList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Box1 = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid #e7f0fa;
  padding: 10px 0;
  position: relative;

  &.row {
    flex-direction: row;
    gap: 15px;
  }

  &.btn {
    justify-content: flex-end;
    border: none;
    align-items: center;
    padding: 5px 0px 0px;
  }

  @media screen and (max-width: 850px) {
    &.row {
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 650px) {
    &.row {
      column-gap: 0px;
    }
  }

  @media screen and (max-width: 480px) {
    &.btn {
      justify-content: center;
    }
  }

  @media screen and (max-width: 375px) {
    &.row {
      row-gap: 20px;
    }
  }
`;

const Left1 = styled.div`
  display: flex;
  width: 80%;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 425px) {
    width: 100%;
  }
`;

const Right1 = styled.div`
  display: flex;
  width: 20%;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    align-items: flex-end;
  }

  @media screen and (max-width: 425px) {
    width: 35%;
    position: absolute;
    bottom: 10px;
    right: 0px;
  }
`;

const JobTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  width: 100%;
  text-align: center;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: flex-start;
    text-align: left;
  }

  span {
    font-size: 18px;
    color: #565757;
    text-align: center;
    padding-left: 5px;

    @media screen and (max-width: 600px) {
      font-size: 17px;
      text-align: left;
      padding-left: 0px;
    }
  }
`;

const LocationInfo = styled.div`
  width: 100%;
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #00aec0;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
`;

const Type1 = styled.div`
  padding: 2px 12px;
  font-size: 15px;
  text-transform: capitalize;
  background-color: rgba(231, 240, 250, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00aec0;
  height: 35px;
  box-sizing: border-box;

  @media screen and (max-width: 600px) {
    font-size: 13px;
    padding: 3px 6px;
    height: 20px;
    align-items: end;
  }
`;

const Skills = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  span {
    color: #565757;
  }

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }
  @media screen and (max-width: 650px) {
    width: 50%;
  }
  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
`;

const Experience = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;
const Qualification = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;
const Specialization = styled.div`
  display: flex;
  gap: 8px;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  color: #909090;

  @media screen and (max-width: 850px) {
    width: 45%;
    flex: unset;
  }

  @media screen and (max-width: 650px) {
    width: 50%;
  }

  @media screen and (max-width: 400px) {
    font-size: 13px;
  }

  @media screen and (max-width: 375px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }

  span {
    color: #565757;
  }
`;

const ViewJobBtn = styled.button`
  background-color: #00aec0;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 7px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    font-size: 13px;
    padding: 9px 18px;
  }
`;

const ApplyNowSticky = styled.div`
  position: fixed;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #00aec0;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 7px;
  align-self: stretch;
  cursor: pointer;
  z-index: 1;

  @media screen and (max-width: 768px) {
    display: flex;
  }

  @media screen and (max-width: 580px) {
    height: 45px;
  }
  @media screen and (max-width: 380px) {
    height: 40px;
  }
`;

const UniversityLogo = styled.div`
  img {
    height: 90px;
    width: 90px;
  }
`;

const More = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`;
const Department = styled.div`
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
const University = styled.div`
  color: #3f3f3f;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  font-family: "ubuntu-medium";
`;
const Country = styled.div`
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #00aec0;
  }
`;

const Card = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 20px 15px;
  display: flex;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    flex-wrap: wrap;
    row-gap: 20px;
  }

  @media (max-width: 800px) {
    padding: 15px 0;
  }

  @media all and (max-width: 768px) {
    width: 100%;
  }

  @media all and (max-width: 675px) {
    flex-direction: column;
  }
`;

const UniversityInfo = styled.div`
  flex: 1.5;
  display: flex;
  border-right: 1px solid #d9d9d9;

  @media (max-width: 1200px) {
    width: 44%;
    flex: unset;
  }

  @media (max-width: 800px) {
    width: 40%;
  }

  @media (max-width: 675px) {
    width: 100%;
    border-right: none;
  }
`;

const Features = styled.div`
  flex: 2;
  border-right: 1px solid #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 25px;

  @media (max-width: 1200px) {
    width: 44%;
    flex: unset;
    border: none;
  }

  @media (max-width: 800px) {
    padding: 0 10px;
    width: 56%;
  }

  @media (max-width: 675px) {
    width: 100%;
  }
`;

const EnquireBtns = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 1200px) {
    width: 90%;
    flex: unset;
    flex-direction: row;
    justify-content: flex-end;
    /* border-top: 1px solid #d9d9d9; */
    padding: 10px 0;
  }
`;

const LogoImg = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 90px;
    height: 90px;
  }
`;

const InfoDiv = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
`;

const Department1 = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  /* @media (max-width: 675px) {
    text-align: center;
  } */
`;

const NameInfo = styled.div`
  color: #3f3f3f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  font-family: "ubuntu-medium";

  /* @media (max-width: 675px) {
    text-align: center;
  } */
`;

const CountryInfo = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  span {
    color: #00aec0;
  }
/* 
  @media (max-width: 675px) {
    text-align: center;
  } */
`;

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 5px;

  @media (max-width: 675px) {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin: 0 auto;
  }
`;

const SingleItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 48%;

  @media (max-width: 675px) {
    justify-content: center;
  }
`;

const Dot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border-color: #00aec0;
  border: 1px solid #00aec0;
`;

const FeatureName = styled.p`
  color: #909090;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  span {
    color: #565757;
    font-family: "ubuntu-regular";
  }

  @media (max-width: 675px) {
    width: 90%;
  }
`;

const EnquireBtn = styled.div`
  color: #00aec0;
  padding: 8px 0;
  border-bottom: 1px solid #00aec0;
  cursor: pointer;
`;

const ViewMoreDetails = styled.button`
  background-color: #00aec0;
  border: none;
  outline: none;
  padding: 8px 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
`;

const BLogCardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 10px;
    margin-top: -30px;

    @media (max-width:1023px){
        grid-template-columns: repeat(3,1fr);
        gap: 30px;
    }
    @media (max-width:768px){
        grid-template-columns: repeat(2,1fr);
        gap: 30px;
    }
    @media (max-width:480px){
        grid-template-columns: repeat(1,1fr);
        gap: 30px;
    }
    
`

const BlogCardWrapper = styled.div`
    cursor: pointer;
    padding-bottom: 30px;
`

const ImageContainer = styled.div`
    background-image: url(${props => props.backgroundImage});
    background-size: contain; 
    background-position: center; 
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
`;

const BlogTitle = styled.p`
    font-size: 16px;
    margin-top: 10px;
    color: #1f93b0;
    font-style: normal;
    line-height: normal;
    text-align: center;
    font-family: "ubuntu-regular";
`