import React from 'react'
import styled from 'styled-components';
import HeaderEdit from '../includes/HeaderEdit';
import Footer from '../includes/Footer';

// images
import images2 from '../../../../assets/images/ausbildungAndFSJ/image2.jpg'
function AusbildungAndFSJ() {

    return (
        <>
            <Container>
                <HeaderEdit backgroundCl="#00AEC0" />
                <Wrapper className="wrapper">
                    <Title>AUSBILDUNG, FSJ &amp; AUPAIR</Title>
                    <DescriptionContainer>
                        <Image src={images2} alt="" />
                        <Descrption>
                            <Subtitle>
                                What is Ausbildung?
                            </Subtitle>
                            <Paragraph>
                                The Ausbildung program is a vocational training program in Germany. It is a dual system of
                                education and training that combines both theoretical and practical learning.
                            </Paragraph>
                            <Paragraph>
                                The duration of Ausbildung program is 3 years. There is no tuition fee for Ausbildung program in
                                Germany. The eligibility is 12 years of full-time education with 55-60% academically.
                            </Paragraph>
                            <Paragraph>
                                Students with age ranging between 18 to 28 can apply for this program. The available intakes for
                                Ausbildung program are in March, April, August and September. Students will get a stipend of 800 to
                                1400 Euros. The stay back duration is 18 months. German language is a must for Ausbildung with
                                B1/B2 level proficiency. Ausbildung is not a degree.
                            </Paragraph>

                        </Descrption>
                    </DescriptionContainer>
                    <Descrption>
                        <Paragraph>
                            The main highlight of Ausbildung program is the job prospect. The confirmed employment after the
                            successful completion of the course makes it highly demandable course. The salary after completing
                            an Ausbildung program in Germany can vary widely depending on the occupation and region, but it
                            typically ranges from 700 to 1500Euros per month.
                        </Paragraph>
                        <Paragraph className='bold'>
                            The programs available are
                        </Paragraph>
                        <List>

                            <li>Nursing</li>
                            <li>IT specialist</li>
                            <li>Mechatronics</li>
                            <li>Physiotherapy</li>
                            <li>Operation theatre assistant</li>
                            <li>Dental Hygienist</li>
                            <li>Radiology</li>
                        </List>
                        <Paragraph>
                            It is a vocational training program in Germany that provides practical skills and qualifications in
                            various professions.
                        </Paragraph>
                        <Subtitle>
                            What is FSJ?
                        </Subtitle>
                        <Paragraph>
                            FSJ stands for &quot;Freiwilliges Soziales Jahr,&quot; which translates to &quot;Voluntary Social Year&quot; in English. It is a
                            program in Germany that allows young people to do voluntary work in social ecological and cultural
                            areas for duration of 6 to 18 months. The program is open to individuals between the ages of 16 and
                            27, who have completed their compulsory education, and wish to engage in social work, gain
                            practical experience, and contribute to society. FSJ participants receive a monthly allowance and are
                            covered by health and social insurance. There are a variety of types of work available to those
                            wishing to participate in a Voluntary Social Year, and all fall under the umbrella of the social sector.
                        </Paragraph>
                        <Paragraph>
                            Participants are fully integrated into the culture of their placement, gaining valuable experience and
                            education, with the pedagogical support of the institution.
                        </Paragraph>
                    </Descrption>
                    <Descrption>
                        <Subtitle>
                            What are the requirements for FSJ?
                        </Subtitle>
                        <List>
                            <li>Age limit 18-26</li>
                            <li>You need to accept 40 regular working hours per week</li>
                            <li>Language level - A2 is needed</li>
                            <li>Necessary documents provided by the authorities of your home country:</li>
                            <li>Residence Permit “for the purpose of employment” and/or Visa</li>
                        </List>
                        <Paragraph className='bold'>
                            Job opportunities for FSJ are in the following fields:
                        </Paragraph>
                        <List>
                            <li>Hospitals</li>
                            <li>Retirement homes</li>
                            <li>Mobile care services</li>
                            <li>Childcare</li>
                            <li>Personal assistance for people in need of care</li>
                            <li>Kindergarten, after-school care or school</li>
                            <li>Kindergarten teacher</li>
                            <li>Social work and care</li>
                            <li>Youth work</li>
                            <li>School companion</li>
                            <li>Children&#39;s villages / homes</li>
                            <li>Rehabilitation facilities</li>
                        </List>
                    </Descrption>
                    <Descrption>
                        <Subtitle>
                        What is Au Pair? 
                        </Subtitle>
                        <Paragraph>
                        An au pair is typically a young person from another country who stays with a host family to learn about the German language and culture,
                         while also helping with childcare and light household duties. The term "aupair" comes from French and means "on equal terms," implying 
                         that the au pair is considered a temporary member of the host family rather than an employee. Aupairs in Germany usually receive room, 
                         board, and a small allowance in exchange for their help with childcare and household tasks. The arrangement is regulated by specific laws
                          and guidelines to ensure both the well-being of the au pair and fair treatment within the host family.
                        </Paragraph>
                        <Paragraph>
                        A Host Family is a family willing to host an Au Pair under the host country’s official program. Host Families must meet certain requirements
                       in order to join the program. For instance, they must have a child under 18 years old, the ability to host an AuPair in their home and respect 
                       the Host Family responsibilities during the program.
                        </Paragraph>
                        <Paragraph className='bold'>
                        General Requirements for Au Pairs in Germany:
                        </Paragraph>
                        <List>
                        	<li>Age limit: 18-26 years at the time of application</li>
                        	<li>Sex: both, men and women can work as an au pair in Germany</li>
                        	<li>Marital status: single or married but may not have their own children</li>
                        	<li>Education: secondary education</li>
                        	<li>Professional: experience in childcare</li>
                        	<li>Language proficiency: basic German language skills, minimum A1 level</li>
                        	<li>Financial: be able to cover travel expenses from their home country to Germany and back home</li>
                        	<li>Time wise: can stay in Germany for 6-12 months</li>
                        	<li>Other: clean criminal record, good health, valid passport </li>

                        </List>
                    </Descrption>
                </Wrapper>
                <Footer />
            </Container>
        </>
    )
}

export default AusbildungAndFSJ

const Container = styled.div`
    width: 100%;
`;

const Wrapper = styled.div`
    margin-top: 150px;
    @media (max-width:1023px){
        margin-top: 100px;
        margin-bottom: 40px;
    }
`
const Title = styled.h1`
    font-size: 40px;
    text-align: center;
    color: #1f93b0;
    font-style: normal;
    line-height: normal;
    font-family: "ubuntu-bold";
    padding: 30px 0;
    @media (max-width:1023px){
        padding: 8px 0;
        font-size: 32px;
    }
`

const DescriptionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 20px 0;
    @media (max-width:1023px){
        display: flex;
        flex-direction: column;
        padding: 4px 0;
    }
`

const Image = styled.img`
    width: 100%;
    border-radius: 20px;
`

const Descrption = styled.div``

const Subtitle = styled.h3`
    font-size: 30px;
    color: #1f93b0;
    font-family: 'ubuntu-bold';
    padding: 10px 0;
    @media (max-width:1023px){
        padding: 20px 0 0 0;
        font-size: 24px;
    }
`

const Paragraph = styled.p`
    padding: 6px 0;
    color: #2b2b2b;
    text-align: justify;
    font-size: 16.5px;
    line-height: 22px;
    font-family: "ubuntu-regular";
    &.bold{
        font-family: 'ubuntu-bold';
    }
    @media (max-width:1023px){
        font-size: 14px;
        line-height: 21px;
        padding: 4px 0;
    }
`

const List = styled.ul`
    color: #2b2b2b;
    text-align: justify;
    font-size: 16.5px;
    line-height: 22px;
    font-family: "ubuntu-regular";
    @media (max-width:1023px){
        font-size: 14px;
        line-height: 21px;
    }
`