import React from "react";
import SpaceText from "./SpaceText";
import styled from "styled-components";

export default function DescriptionText({ label }) {
  return (
    <Description>
      {/* <SpaceText />  */}
      {label}
    </Description>
  );
}
const Description = styled.p`
  color: #2b2b2b;
  line-height: 26px;
  margin-bottom: 20px;
`;
